






























@import url('https://fonts.googleapis.com/css?family=Roboto:100,300');
$iPhoneColour: #000;
$deviceWidth: 404px;
$deviceHeight: 800px;
body {
	background: lighten($iPhoneColour,60);
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
* {
	box-sizing: border-box;
}
.info {
	
	font-size: 24px;
	padding: 30px;
	b {
		font-weight: 700;
	}
	small {
		font-size: 12px;
	}
}
.close-btn {
	z-index: 99999;
	position: absolute;
	top: 50px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	width: 200px;
}
.iphone div.component {
	display: block;
	position: absolute;
}
.iphone {
	z-index: 9999999;
	zoom: 0.75;
	width: $deviceWidth;
	height: $deviceHeight;
	display: block;
	position: absolute;
	left: 50%;
	top: 120px;
	transform: translateX(-50%);
	.outer {
		width: 98%;
		height: 100%;
		top: 0;
		left: 1%;
		border-radius: 64px;
		background: $iPhoneColour;
		box-shadow: 10px 30px 200px rgba(0,0,0,0.95);
	}
	.inner {
		width: 94%;
		height: 98%;
		border-radius: 57px;
		left: 3%;
		top: 1%;
		background: lighten($iPhoneColour,10);
	}
	.band {
		width: 3%;
		height: 10px;
		background: lighten($iPhoneColour,10);
	}
	.tl {
		left: 1%;
		top: 100px;
	}
	.tr {
		right: 1%;
		top: 100px;
	}
	.bl {
		left: 1%;
		bottom: 100px;
	}
	.br {
		right: 1%;
		bottom: 100px;
	}
	.screen {
		background: #fff;
		width: 86%;
		height: 94%;
		left: 7%;
		top: 3%;
		border-radius: 37px;
		overflow: hidden;
		.container {
			width: 100%;
			height: 100%;
			zoom: 0.9;
		}
		.time, .date {
			width: 100%;
			text-align: center;
			color: #fff;
		}
	}
	.speaker-section {
		width: 48.5%;
		height: 3.25%;
		top: 3%;
		left: 25.75%;
		background: lighten($iPhoneColour,10);
		border-bottom-right-radius: 19.5px;
		border-bottom-left-radius: 19.5px;
		.scanner {
			height: 8px;
			width: 8px;
			background: darken($iPhoneColour,10);
			border-radius: 4px;
			left: 27.5%;
			top: 25%;
			transform: translate(0,-50%);
		}
		.camera {
			height: 10px;
			width: 10px;
			background: darken($iPhoneColour,10);
			border-radius: 5px;
			right: 26.5%;
			top: 25%;
			transform: translate(0,-50%);
		}
		.speaker {
			width: 23%;
			height: 4px;
			background: darken($iPhoneColour,10);
			border-radius: 4px;
			left: 50%;
			top: 25%;
			transform: translate(-50%,-50%);
		}
	}
	.power, .mute, .vol {
		width: 2%;
		position: absolute;
		background: $iPhoneColour;
		border-radius: 2px;
	}
	.power {
		height: 4%;
		top: 15.5%;
	}
	.vol {
		height: 7.25%;
		&.up {
			top: 24.5%;
		}
		&.down {
			top: 33.5%;
		}
	}
	.mute {
		height: 11.5%;
		top: 15.5%;
		right: 0;
	}
}
